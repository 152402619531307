import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-scroll";
import logo from '../assets/imgs/logo-hd.svg';

function Footer(props) {

  if(props.mobile){
    return (
      <Container fluid id="footer" className='block footer mobile'>
          <div className='bgimg'></div>
          <Row>
            <Col xs={12}>
              <div className='menu-horizontal'>
                <Link
                  to="home"
                  smooth={true}
                  duration={1000}>
                  Home
                </Link>
                <Link
                  to="about"
                  smooth={true}
                  duration={500}
                  >
                  Sobre nós
                </Link>
                
                <Link
                  to="servicos"
                  smooth={true}
                  duration={500}
                  >
                  Nossos Serviços
                </Link>
              
                <Link
                  to="valores"
                  smooth={true}
                  duration={500}
                  >
                  Nossos Valores
                </Link>
                
                <Link
                  to="contact"
                  smooth={true}
                  duration={500}
                  >
                  Contato
                </Link>
              </div>
            </Col>
            <Col xs={12}>
              <div className='dados'>
                Copyright © 2023. Home Developer.<br />
                Todos os direitos reservados<br /><br />
                CNPJ: 38.146.976/0001-40<br />
              </div>
            </Col>
            <Col xs={12}>
              <div className='logo'>
                <img src={logo} alt="logo"/>
                <span className='nome-empresa'><span>Home Developer</span></span>
              </div>
            </Col>
          </Row>
        </Container>
    );
  }

  return (
    <Container fluid id="footer" className='block footer'>
        <div className='bgimg'></div>
        <Row>
          <Col>
            <div className='menu-horizontal'>
              <Link
                to="home"
                smooth={true}
                duration={1000}>
                Home
              </Link>
              <Link
                to="about"
                smooth={true}
                duration={500}
                >
                Sobre nós
              </Link>
              
              <Link
                to="servicos"
                smooth={true}
                duration={500}
                >
                Nossos Serviços
              </Link>
            
              <Link
                to="valores"
                smooth={true}
                duration={500}
                >
                Nossos Valores
              </Link>
              
              <Link
                to="contact"
                smooth={true}
                duration={500}
                >
                Contato
              </Link>
            </div>
          </Col>
          <Col>
            <div className='logo'>
              <img src={logo} alt="logo"/>
              <span className='nome-empresa'><span>Home Developer</span></span>
            </div>
          </Col>
          <Col>
            <div className='dados'>
              Copyright © 2023. Home Developer.<br />
              Todos os direitos reservados<br /><br />
              CNPJ: 38.146.976/0001-40<br />
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default Footer;
