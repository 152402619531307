import { faArrowsSplitUpAndLeft, faClipboardCheck, faGraduationCap, faHandshake, faLightbulb, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";

function Valores(props) {

  let valoresEmpresa = [
    {
      "icon": <FontAwesomeIcon icon={faLightbulb}/>,
      "titulo": "Inovação & Soluções Criativas",
      "descricao": "Buscamos continuamente soluções tecnológicas inovadoras e abordagens criativas para resolver desafios empresariais."
    },
    {
      "icon": <FontAwesomeIcon icon={faStreetView}/>,
      "titulo": "Cliente no Centro",
      "descricao": "Colocamos as necessidades e objetivos dos clientes no centro de todas as nossas atividades e decisões."
    },
    {
      "icon": <FontAwesomeIcon icon={faClipboardCheck}/>,
      "titulo": "Excelência e Transparência",
      "descricao": "Compromisso com a excelência na entrega de produtos e serviços, mantendo altos padrões éticos e total transparência em nossas ações."
    },
    {
      "icon": <FontAwesomeIcon icon={faHandshake}/>,
      "titulo": "Colaboração Efetiva",
      "descricao": "Promovemos um ambiente de trabalho colaborativo, onde equipes trabalham juntas para alcançar metas comuns."
    },
    {
      "icon": <FontAwesomeIcon icon={faGraduationCap}/>,
      "titulo": "Aprendizado Contínuo & Desenvolvimento",
      "descricao": "Cultivamos uma cultura de aprendizado constante, incentivando a atualização de conhecimentos e habilidades."
    },
    {
      "icon": <FontAwesomeIcon icon={faArrowsSplitUpAndLeft}/>,
      "titulo": "Adaptação Ágil",
      "descricao": "Estamos prontos para se adaptar rapidamente às mudanças tecnológicas e de mercado, atendendo às demandas em constante evolução."
    }
]

  if(props.mobile){
    return (
      <Container fluid id="valores" className={props.mobile?'block valores mobile':'block valores'}>
        <div className='bgimg'></div>
        <Row>
          <Col>
            <div className='detail titulo-valor'>
              <span>Nossos Valores</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='detail'>
              {valoresEmpresa.map((e,i) => {
                return (
                  <div className='valor'>
                    {e.icon}
                    <h3>{e.titulo}</h3>
                    <p>
                      {e.descricao}
                    </p>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid id="valores" className='block valores'>
        <div className='bgimg'></div>
        <Row>
          <Col>
            <div className='detail titulo-valor'>
              <span>Nossos Valores</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className='detail'>
              {valoresEmpresa.map((e,i) => {
                if(i%2===0){
                  return (
                    <div className='valor'>
                      {e.icon}
                      <h3>{e.titulo}</h3>
                      <p>
                        {e.descricao}
                      </p>
                    </div>
                  );
                }
                return <></>;
              })}
            </div>
          </Col>
          <Col xs={6}>
            <div className='detail'>
              {valoresEmpresa.map((e,i) => {
                if(i%2===1){
                  return (
                    <div className='valor'>
                      {e.icon}
                      <h3>{e.titulo}</h3>
                      <p>
                        {e.descricao}
                      </p>
                    </div>
                  );
                }
                return <></>;
              })}
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default Valores;
