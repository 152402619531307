import './App.css';
import HomeDeveloper from './modules/homedeveloper/HomeDeveloper';

function App() {

  return (
    <HomeDeveloper />
  );
}

export default App;
