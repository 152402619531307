import { faCircleNodes, faGlobe, faPeopleGroup, faPersonChalkboard, faUserGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";

function Servicos(props) {

  const servicos = [
    {
      icon: <FontAwesomeIcon icon={faPeopleGroup}/>,
      titulo: "Consultoria Especializada em Transformação Digital",
      descricao: "Nossa equipe de especialistas em tecnologia compreende profundamente as necessidades e os desafios de seu setor. Trabalhamos lado a lado com você para desenvolver estratégias de transformação digital personalizadas que impulsionam o crescimento.",
    },
    {
      icon: <FontAwesomeIcon icon={faUserGear}/>,
      titulo: "Desenvolvimento de Sistemas",
      descricao: "Criamos soluções de software personalizadas que automatizam processos, simplificam operações e melhoram a tomada de decisões. Nossos sistemas são projetados para se adaptar às necessidades exclusivas de sua empresa.",
    },
    {
      icon: <FontAwesomeIcon icon={faGlobe}/>,
      titulo: "Presença Online e Marketing Digital",
      descricao: "Uma presença forte na web é essencial hoje em dia. Desenvolvemos sites de alto desempenho e estratégias de marketing digital que aumentam sua visibilidade e atraem clientes em potencial.",
    },
    {
      icon: <FontAwesomeIcon icon={faCircleNodes}/>,
      titulo: "Inteligência Artificial e Análise de Dados",
      descricao: "Utilizamos tecnologias de ponta, como inteligência artificial e análise de dados avançada, para fornecer insights acionáveis que impulsionam sua estratégia de negócios.",
    },
    {
      icon: <FontAwesomeIcon icon={faPersonChalkboard}/>,
      titulo: "Treinamento e Capaticação",
      descricao: "Não apenas entregamos soluções, mas também capacitamos sua equipe para utilizar plenamente as ferramentas digitais que implementamos.",
    }
  ]

  if(props.mobile){
    return (
      <Container fluid id="servicos" className={props.mobile?'block servicos mobile':'block servicos'}>
          <div className='bgimg'></div>
          <Row className={props.mobile? "mobile" : ""}>
            <Col xs={props.mobile?12:3}>
              <div className='detail'>
                <span className='titulo-servicos'>Nossos Serviços</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={props.mobile?12:9}>
              <div className='detail'>
                {servicos.map(e => {
                  return (
                    <div className='servico'>
                      {e.icon}
                      <h3>{e.titulo}</h3>
                      <p>
                        {e.descricao}
                      </p>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
    );
  }

  return (
    <Container fluid id="servicos" className={props.mobile?'block servicos mobile':'block servicos'}>
        <div className='bgimg'></div>
        <Row>
          <Col xs={props.mobile?12:3} className={props.mobile? "mobile" : ""}>
            <div className='detail'>
              <span className='titulo-servicos'>Nossos Serviços</span>
            </div>
          </Col>
          <Col xs={props.mobile?12:9}>
            <div className='detail'>
              {servicos.map(e => {
                return (
                  <div className='servico'>
                    <div>
                      <h3>{e.titulo}</h3>
                      <p>
                        {e.descricao}
                      </p>
                    </div>
                    {e.icon}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default Servicos;
