import { Col, Row, Stack } from "react-bootstrap";
import { Link } from "react-scroll";

function Intro(props) {
    return (
    <Stack direction="vertical" className={props.mobile?'block intro mobile':'block intro'} id="home">
        <div className='bgimg'></div>
        <Row>
          {props.mobile? <></>: <Col className="subblock"></Col>}
          <Col className="subblock" xs={props.mobile?12:7}>
            <div className='detail'>
              <h1>Home Developer: A resposta para sua Transformação Digital.</h1>
              <h2>Transforme sua empresa com tudo o que há de melhor da tecnologia</h2>
              <p>A Home Developer é o parceiro ideal para impulsionar sua empresa na era digital. Conte conosco para transformar desafios em oportunidades e alcançar o sucesso online.</p>
              <Link
                to="contact"
                smooth={true}
                duration={500} 
                className='botao'
                >
                Entrar em contato
              </Link>
            </div>
          </Col>
          {!props.mobile? <></>: <Col className="subblock"></Col>}
        </Row>
      </Stack>
  );
}

export default Intro;
