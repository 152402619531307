import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import logo from '../assets/imgs/logo-hd.svg';

function Header(props) {

  const prevScrollY = useRef(0);

  const [esconderMenu, setEsconderMenu] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const limit = document.getElementsByClassName("footer")[0].offsetTop;
      //const limit = (qtTelas-1)*sizeBlock;
      const currentScrollY = window.scrollY;
      if (currentScrollY >= limit) {
        setEsconderMenu(true);
      }else{
        setEsconderMenu(false);
      }

      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [esconderMenu]);

  if(props.mobile){
    return (
      <header className={"HomeDeveloper-header mobile "+(open?"open":"")+" "+(esconderMenu?"esconder":"")}>
        <div className='logo'>
            <img src={logo} alt="logo"/>
            <span>Home Developer</span>
        </div>
        <nav>
          <span onClick={() => {setOpen(!open)}}>
            <FontAwesomeIcon icon={faBars} />
          </span>
        </nav>
        <nav className="menu">
            <Link
              to="home"
              smooth={true}
              duration={500}
              onClick={() => {setOpen(false)}}
              >
              Home
            </Link>
            <Link
              to="about"
              smooth={true}
              duration={500}
              onClick={() => {setOpen(false)}}
              >
              Sobre nós
            </Link>
            <Link
              to="servicos"
              smooth={true}
              duration={500}
              onClick={() => {setOpen(false)}}
              >
              Nossos Serviços
            </Link>
            <Link
              to="valores"
              smooth={true}
              duration={500}
              onClick={() => {setOpen(false)}}
              >
              Nossos Valores
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              onClick={() => {setOpen(false)}}
              >
              Contato
            </Link>
        </nav>
      </header>
    );
  }

  return (
    <header className={"HomeDeveloper-header "+(esconderMenu?"esconder":"")}>
        <div className='logo'>
            <img src={logo} alt="logo"/>
            <span>Home Developer</span>
        </div>
        <nav>
            <Link
              to="home"
              smooth={true}
              duration={500}
              >
              Home
            </Link>
            <Link
              to="about"
              smooth={true}
              duration={500}
              >
              Sobre nós
            </Link>
            
            <Link
              to="servicos"
              smooth={true}
              duration={500}
              >
              Nossos Serviços
            </Link>
            
            <Link
              to="valores"
              smooth={true}
              duration={500}
              >
              Nossos Valores
            </Link>
              
            <Link
              to="contact"
              smooth={true}
              duration={500}
              >
              Contato
            </Link>
            
        </nav>
      </header>
  );
}

export default Header;
