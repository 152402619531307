import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import emailjs from 'emailjs-com';
import { useState } from "react";
import env from "react-dotenv";
import { PatternFormat } from "react-number-format";

function Contato(props) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [informacao, setInformacao] = useState("");
  const [email, setEmail] = useState("");


  function sendEmail(e) {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }else{
      emailjs.send(
        env.REACT_APP_SERVICE_ID, 
        env.REACT_APP_TEMPLATE_ID,{
        Email: email,
        Telefone: telefone,
        Informacao: informacao,
        Nome: nome,
        }, 
        env.REACT_APP_USER_ID);
        setShow(true);
    }
  }

  function getFormulario(){
    return (
      <div className='detail form'>
        <Form onSubmit={sendEmail} >
          <h1>Solicite contato agora!</h1>
          <Form.Group className="mb-3" controlId="nomeContato" >
            <Form.Label>Nome para contato</Form.Label>
            <Form.Control required type="text" placeholder="Nome Completo" onChange={(e) => setNome(e.target.value)}/>
            <Form.Control.Feedback type="invalid">
              Por favor, informe seu nome.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="emailContato">
            <Form.Label>E-mail</Form.Label>
            <Form.Control required type="email" placeholder="meuemail@email.com" onChange={(e) => setEmail(e.target.value)}/>
            <Form.Control.Feedback type="invalid">
              Por favor, informe um email válido.
            </Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group className="mb-3" controlId="telefoneContato">
            <Form.Label>Telefone</Form.Label>
            <PatternFormat
              format="(##) #####-####"
              mask="_"
              isNumericString={true}
              allowEmptyFormatting={true} className="form-control"
              onChange={(e) => setTelefone(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, informe seu número de telefone.
            </Form.Control.Feedback> { /*  */ }
          </Form.Group>
          <Form.Group className="mb-3" controlId="informacaoContato">
            <Form.Label>Nos conte brevemente o que deseja:</Form.Label>
            <Form.Control required as="textarea" rows={3} onChange={(e) => setInformacao(e.target.value)}/>
            <Form.Control.Feedback type="invalid">
              Por favor, nos dê uma prévia do que deseja.
            </Form.Control.Feedback>
          </Form.Group>
          <Button type="submit">Solicitar contato</Button>
        </Form>
      </div>
    );
  }

  function getModal(){
    return (
      <div
        className={"modal "+(show?"show-flex":"show-hidden")}
        style={{ zIndex:"9999", flexDirection: 'column', justifyContent: "space-around", position: 'fixed', top: "50%", transform: "translateY(-50%)", background: "rgba(0,0,0,0.8)" }}
      >
      <Modal.Dialog show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Recebemos a sua solicitação!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Em breve estaremos entrando em contato para conversarmos mais sobre o seu projeto!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
    );
  }

  if(props.mobile){
    return (
      <Container fluid id="contact" className='block contact mobile'>
        <div className='bgimg'></div>
        <Row>
          <Col xs="12">
            <div className='detail sugestao'>
              <h1>Se interessou?</h1>
              <h2>
                Solicite nosso contato!
              </h2>
              <p>Preencha o formulário a seguir e nos conte um pouco o que deseja! Entraremos em contato o mais breve possível.</p>
            </div>
          </Col>
          <Col xs="12">
            {getFormulario()}
          </Col>
        </Row>
        {getModal()}
      </Container>
    );  
  }

  return (
    <Container fluid id="contact" className='block contact'>
      <div className='bgimg'></div>
      <Row>
        <Col>
          {getFormulario()}
        </Col>
        <Col>
          <div className='detail sugestao'>
            <h1>Se interessou?</h1>
            <h2>
              Solicite nosso contato!
            </h2>
            <p>Preencha o formulário ao lado e nos conte um pouco o que deseja! Entraremos em contato o mais breve possível.</p>
          </div>
        </Col>
      </Row>
      {getModal()}
    </Container>
  );
}

export default Contato;
