import { useState } from 'react';
import { Stack } from 'react-bootstrap';
import './assets/style/HomeDeveloper.css';
import Contato from './components/Contato';
import Footer from './components/Footer';
import Header from './components/Header';
import Intro from './components/Intro';
import Servicos from './components/Servicos';
import Sobre from './components/Sobre';
import Valores from './components/Valores';

function HomeDeveloper() {

  const [mobile, setMobile] = useState(false);

  if(window.innerWidth < 1200 && !mobile){
    setMobile(true);
  }
  
  let intro = () => {
    return (
      <Intro mobile={mobile}></Intro>
    );
  };

  let sobre_nos = () => {
    return (<Sobre mobile={mobile}></Sobre>);
  };

  let servicos = () => {
    return (
      <Servicos mobile={mobile}></Servicos>
    );
  }

  let valores = () => {
    return (
      <Valores mobile={mobile}></Valores>
    );
  }

  let contact = () => {
    return (
      <Contato mobile={mobile}></Contato>
    );
  };

  let footer = () => {
    return (
      <Footer mobile={mobile}></Footer>
    );
  };

  let header = () => {
    return (
      <Header mobile={mobile}></Header>
    );
  }

  let blocks = [
    intro, sobre_nos, servicos, valores, contact, footer
  ]

  return (
    <div className="HomeDeveloper">
        <Stack direction='vertical' className="content">
          {blocks.map(i => i())}
        </Stack>
        {header()}
    </div>
  );
}

export default HomeDeveloper;
