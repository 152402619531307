import { Col, Container, Row } from "react-bootstrap";

function Sobre(props) {

  if(props.mobile){
    return (
      <Container fluid id="about" className='block about mobile'>
        <div className="bgimg mobile"></div>
        <Row className={props.mobile? "mobile" : ""}>
          <Col xs={props.mobile?12:3}>
            <div className='detail'>
              <span className='titulo-sobre'>Sobre Nós</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={props.mobile?12:7}>
            <div className='detail'>
              <p>
              A Home Developer nasceu do desejo de entusiastas de tecnologia em tornar a transformação digital 
              acessível para pequenas empresas locais, superando os custos elevados. Com dedicação e criatividade, 
              tornamos a transformação digital uma realidade para todos.
              </p>

              <h2>
                Modernizando Empresas para o Futuro: Sua Parceria em Transformação Digital
              </h2>

              <p>
              A Home Developer é a ponte para empresas que buscam adotar a tecnologia no mundo digital. Capacitamos 
              empresas de todos os tamanhos a automatizar processos, expandir online e otimizar a gestão através da 
              tecnologia.
              </p>

              
              <h2>
                Nossa Visão
              </h2>
              <p>
              Visualizamos um futuro onde a tecnologia é aliada, não uma barreira. Na Home Developer, acreditamos na 
              jornada de transformação digital que começa com a compreensão das necessidades de nossos clientes. Nosso 
              objetivo é impulsionar a eficiência, inovação e competitividade das empresas no mercado.
              </p>
            </div>
          </Col>
          <Col>
          </Col>
        </Row>
      </Container>
    );
  }
  
  return (
    <Container fluid id="about" className='block about'>
        <div className='bgimg'></div>
        <Row>
          <Col xs={props.mobile?12:7}>
            <div className='detail'>
              <h1>
                Sobre nós
              </h1>
              <p>
              A Home Developer nasceu do desejo de entusiastas de tecnologia em tornar a transformação digital 
              acessível para pequenas empresas locais, superando os custos elevados. Com dedicação e criatividade, 
              tornamos a transformação digital uma realidade para todos.
              </p>

              <h2>
                Modernizando Empresas para o Futuro: Sua Parceria em Transformação Digital
              </h2>

              <p>
              A Home Developer é a ponte para empresas que buscam adotar a tecnologia no mundo digital. Capacitamos 
              empresas de todos os tamanhos a automatizar processos, expandir online e otimizar a gestão através da 
              tecnologia.
              </p>

              
              <h2>
                Nossa Visão
              </h2>
              <p>
              Visualizamos um futuro onde a tecnologia é aliada, não uma barreira. Na Home Developer, acreditamos na 
              jornada de transformação digital que começa com a compreensão das necessidades de nossos clientes. Nosso 
              objetivo é impulsionar a eficiência, inovação e competitividade das empresas no mercado.
              </p>
            </div>
          </Col>
          <Col>
          </Col>
        </Row>
      </Container>
  );
}

export default Sobre;
